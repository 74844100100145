$primary: #84cc2c;

@import "~bootstrap/scss/bootstrap";

@import "./_style";

.text-negative {
  color: var(--bs-danger);
}

.text-positive {
  color: var(--bs-success);
}

#editionTabsContent {
  .badge {
    min-width: 2.625rem;
    line-height: var(--bs-body-line-height) !important;
  }
}

.appstore {
  // Disable text selection for Safari Live Text
  -webkit-user-select: none;
  user-select: none;
}
