.bjcp-style {
  #stats {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .one_stat {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-bottom: 2px;
    background: var(--bs-tertiary-bg);
    font-size: 12px;

    .title {
      display: flex;
      height: 48px;
      width: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .graph_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    justify-content: center;
  }

  .bar {
    position: relative;
    height: 4px;
    width: calc(100% - 16px);
    margin: 0 8px;
    background: var(--bs-tertiary-color);
  }

  .inner_bar {
    position: absolute;
    height: 100%;
    width: 0;
    background: var(--bs-primary);
  }

  .gradient {
    width: 32px;
    height: 32px;
    margin: 8px 0 8px 8px;
    border-radius: 2px;
    background: var(--bs-dark-bg-subtle);
  }
}

@media print {
  .bjcp-style {
    .style-column {
      width: 100% !important;
    }

    .style-header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

// .red {
//   color: #ff4136;
// }

// .green {
//   color: #2ecc40;
// }

// .style-container {
//   h3 {
//     margin-top: 1em;
//     border-bottom: 1px solid #b7b7b7;
//   }

//   p {
//     line-height: 20px;
//   }

//   #stats {
//     width: 100%;
//     position: relative;
//   }

//   #description,
//   #commercial {
//     padding-top: 0.5em;
//   }

//   .one_stat {
//     position: relative;
//     width: 100%;
//     height: 48px;
//     margin-bottom: 2px;

//     display: -webkit-box;
//     display: -moz-box;
//     display: box;

//     -moz-box-orient: horizontal;
//     -webkit-box-orient: horizontal;
//     box-orient: horizontal;
//   }

//   .title {
//     height: 48px;
//     width: 40px;
//     padding-left: 8px;
//     line-height: 48px;
//     vertical-align: middle;
//     text-align: center;
//     font-size: 14px;

//     -webkit-box-flex: 0;
//     -moz-box-flex: 0;
//     box-flex: 0;
//   }

//   .srm.has_srm .gradient {
//     width: 32px;
//     height: 32px;
//     margin: 8px 4px 8px 8px;
//     border-radius: 2px;

//     -webkit-box-flex: 0;
//     -moz-box-flex: 0;
//     box-flex: 0;
//   }

//   .graph_wrapper {
//     height: 48px;
//     position: relative;

//     -webkit-box-flex: 1;
//     -moz-box-flex: 1;
//     box-flex: 1;
//   }

//   .top,
//   .bottom {
//     width: 100%;
//     position: absolute;
//   }

//   .top {
//     height: 22px;
//     top: 0;
//     right: 0;
//   }

//   .bottom {
//     text-align: center;
//     font-size: 12px;
//     height: 24px;
//     bottom: 0;
//     right: 0;
//   }

//   .bar {
//     width: 95%;
//     height: 4px;
//     margin: 18px auto 0 auto;
//     position: relative;
//   }

//   .inner_bar {
//     height: 100%;
//     width: 0;
//     top: 0;
//     left: 0;
//     position: absolute;
//   }

//   #print-header {
//     display: block;
//   }

//   #print-header h1 {
//     font-size: 1.6em;
//     font-weight: normal;
//     font-family: "Open Sans", sans-serif;
//     color: #000;
//   }

//   /* Defaults for the bars and links */

//   .inner_bar {
//     background: #8ecf3d;
//   }
//   .one_stat {
//     background: #f7f7f7;
//   }
//   .bar {
//     background: #b7b7b7;
//   }
//   a,
//   a :hover,
//   a :visited,
//   a :active {
//     color: rgba(0, 122, 255, 1);
//   }

//   .corner-label {
//     display: block;
//     width: 60px;
//     padding: 0.3em 0.6em 0.3em 0.6em;
//     font-size: 8pt;
//     font-weight: bold;
//     line-height: 1;
//     color: #b7b7b7;
//     background: #f7f7f7;
//     text-align: center;
//     white-space: nowrap;
//     vertical-align: baseline;
//     border-radius: 0.25em;

//     position: absolute;
//     top: 16px;
//     right: 16px;
//     left: auto;
//   }
// }

// #credits {
//   display: block;
//   font-style: italic;
//   border-top: 1px dashed #b7b7b7;
//   font-size: 0.6em;
//   color: #b7b7b7;
// }

// @media print {
//   body {
//     font-size: 13px;
//   }

//   p {
//     line-height: 17px;
//   }

//   h3 {
//     margin-top: 0.25em;
//   }
// }
